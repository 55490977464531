@import '../../styles/vars';

.edit-project__upload-section {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;

  > div {
    padding: 20px;
    flex: 1;
    text-align: center;
    border: 1px solid $light_border;

    &:first-of-type {
      margin-right: 10px;
    }
  }
}
