@import '../../styles/vars';

.chevvy__container {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid black;
  display: inline;
  margin-left: 4px;
  transition: transform $transition-timing;
}

.chevvy__container--active-rotate {
  transform: rotate(180deg);
}

.chevvy__container--active-color {
  border-top-color: $active-color;
}
