@import '../../styles/vars';

.menu {
  position: relative;
  display: inline-block;
  text-align: center;
}

.menu__button {
  display: inline-flex;
  justify-content: center;
  width: 100%;
  padding-left: 1rem;
  padding-right: 0rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  background: none;
  border: none;
}

.menu__icon {
  margin-right: -0.25rem;
  margin-left: 0.5rem;
  width: 1.25rem;
  height: 1.25rem;
  color: '#9a9fa7';
}

.menu__items {
  transform-origin: top right;
  position: absolute;
  right: 0;
  width: 6rem;
  border-radius: 0.375rem;
  background-color: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.12);
  z-index: 2;
}

.menu__padding {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.menu__item1 {
  background-color: #f3f4f6;
}

.menu__item2 {
  color: rgba(55, 65, 81, 1);
  background-color: white;
}
.menu__item3 {
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: block;
  border: none;
  width: 100%;
  z-index: 0;
  cursor: pointer;
}

.menu__item3:hover {
  color: rgba(55, 65, 81, 1);
  text-decoration: none;
}

.deleteModal__container {
  position: relative;
  z-index: 100;
}

.deleteModal__wrapper {
  // position: fixed;
  // top: 0px;
  // right: 0px;
  // bottom: 0px;
  // left: 0px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex: 0 0 auto;
  transition: box-shadow 200ms ease 0s;
  padding: 20px 20px 14px;

  h1 {
    margin-bottom: 0;
    font-size: 16px;
  }
}

.deleteModal__panel {
  width: 100%;
  max-width: 24rem;
  border-radius: 0.25rem;
  background: white;
  padding: 1rem;
}

.deleteModal__backdrop {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: $trans-background;
}

.deleteModal__heading {
  font-size: 16px;
  margin-bottom: 0;
}

.deleteModal__buttonGroup {
  padding: 15px 0px;
}
