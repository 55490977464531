@import '../../styles/vars';

.assets__cropBox {
  max-height: 250px;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
}

.assets__cropText {
  text-align: center;
  height: 30px;
  position: relative;
  top: -30px;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  transition: hover 0.2s ease-in-out;

  &:hover {
    color: $link_bright_txt;
  }
}

.assets__cropBox--open {
  padding-bottom: 20px;
}

.assets__cropText--inner {
  position: relative;
  top: 15px;
}

.assets__commentsHeader--comments {
  margin-top: 20px !important;
}
