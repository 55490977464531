@import 'reset.scss';
@import 'fonts.scss';
@import 'vars.scss';
@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');

a {
  color: $active_color;
  text-decoration: none;
}

a:hover {
  color: $active_color;
  text-decoration: underline;
}
a:active {
  color: $active_color;
}

a:focus {
  color: $active_color;
}

html {
  box-sizing: border-box;
  height: 100%;
}

body {
  height: 100%;
  line-height: 1.4;
  font-size: 11px;
  color: $dark_text;

  @include medium-screen {
    font-size: 13px;
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
  // make font better looking
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  // text-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
}

#root {
  min-height: 100%;
  display: flex;
}

img {
  max-width: 100%;
}

form {
  width: 100%;
}

input {
  // hack for atlas kit
  height: auto !important;
}

h1 {
  margin-bottom: $size-huge;
}

h2 {
  margin-bottom: $size-large;
}

p {
  margin-bottom: $size-small;
}

td {
  // overflow: visible !important;
  // white-space: nowrap !important;
}

tr {
  border-bottom: 1px solid $table-border;
}

img {
  // weird hack to stop browser adding padding to images inside a
  vertical-align: top;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  opacity: 0;
  animation: fadeIn 0.3s 0.5s ease-in-out;
  animation-fill-mode: forwards;
}

video {
  max-height: 100%;
  max-width: 100%;
}

embed {
  height: 100%;
  width: 100%;
}

.to-upper {
  text-transform: uppercase;
}

.custom-select__assign-label {
  font-size: 9px;
  font-style: italic;
}

.custom-select__option--is-disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
  pointer-events: none;
}

.custom-select__not-verified {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.custom-select__not-verified--top {
  padding-right: 10px;
}

.custom-select__not-verified--bottom {
  font-size: 9px;
  color: red;
  padding-right: 10px;
}

.custom-select__container {
  display: flex;
  position: relative;
}

.custom-select__pic {
  margin-right: 10px;
}

.custom-select__cancel {
  position: absolute;
  top: 0;
  right: 0;
  color: white;
  font-size: 16px;
  cursor: pointer;
  padding: 5px;

  &:hover {
    color: red;
  }
}

.warning-message {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: red;
  color: white;
  z-index: 500005;
  text-align: center;
  padding: 3px;
  font-size: 11px;
}
