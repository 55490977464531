@import '../../styles/vars';

// .pdf-modal__container {
// display: flex;
// }

.pdf-modal__row {
  margin: $padding;
  // flex: 1;
  // display: flex;
  // justify-content: space-between;
  // flex-direction: column;
  align-items: center;

  h3 {
    padding-bottom: $padding;
  }

  button {
    margin-top: $padding;
  }

  // > div {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   text-align: center;
  // }
}
