@import '../../styles/vars';

.dashboard__container {
  min-height: 100%;
  height: 100%;

  @include medium-large-screen-max {
    width: 100%;
  }
}

.dashboard__container_desktop {
  display: grid;
  grid-template-columns: 1fr auto auto;
}

.dashboard__container_mobile {
  display: flex;
  position: fixed;
}

.dashboard__container--aniContainer {
  transition: left 0.3s ease-in-out;
  left: 0;
  top: 0;
  display: flex;
  z-index: 100;
}

.dashboard__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px 5px 16px;
  background: $dashboard_top_bg;
  min-height: 48px;
}

.dashboard__split {
  // margin-bottom: $top-nav-height-sub + $top-nav-height;
  display: flex;
  flex-direction: column;
  background: $left_nav_bg;
  transition: left 0.3s ease-in-out;
  left: 0;
  height: calc(100vh - 112px);
}

.dashboard__split_mobile {
  min-width: 100%;

  @include medium-screen {
    width: calc(100% - #{$small-split});
    min-width: auto;
  }

  @include large-screen {
    width: calc(100% - #{$medium-split});
  }
  @include x-large-screen {
    width: calc(100% - #{$large-split});
  }
}

.dashboard__split_desktop {
  flex-grow: 1;
  min-width: 10px;
}

.dashboard__container--active {
  position: relative;
  left: -100vw;

  @include medium-screen {
    left: 0;
  }
}

.dashboard__filter-menu--click {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dashboard__filter-menu {
  position: relative;
}

.dashboard__filter-menu-inner {
  padding: $padding;
}

.dashboard__filter-menu-container {
  position: absolute;
  background-color: white;
  border: 1px solid $light_border;
  right: 0;
  top: 10px;
  width: 300px;
  z-index: 10;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
  max-height: 0;
  overflow: hidden;
  transition: all $transition-timing;
  opacity: 0;

  @include medium-screen {
    width: 440px;
  }
}

.dashboard__filter-menu-container--small {
  width: 175px;
}

.dashboard__filter-menu-container--comfortable {
  width: 110px;
}

.dashboard__filter-menu--open {
  max-height: 500px;
  opacity: 1;
  overflow: auto;
}

.dashboard__table-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  margin: $small-padding $small-padding 0 $small-padding;
  // border-top-left-radius: $border-radius;
  // border-top-right-radius: $border-radius;
  padding-bottom: 50px;

  @include medium-screen {
    padding-bottom: 0;
  }
}

.dashboard__filter-menu--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.dashboard__filter-menu--sort {
  display: flex;
  flex-direction: column;
}

.dashboard__filter-item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 7px;

  h5 {
    margin-top: 0;
    margin-left: 24px;
  }
}

.dashboard__mobileBottomNav {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background: $top_header_bg;
  z-index: 10;
  display: flex;
  text-align: center;
  justify-content: center;
  color: white;

  @include medium-screen {
    display: none;
  }
}

.dashboard__mobileBottomNav--link {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  cursor: pointer;
}
