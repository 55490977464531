.company-suggestion__list {
  columns: 3;
  margin-bottom: 20px;
  padding-top: 10px;

  span {
    display: block;
    margin-bottom: 10px;
  }
}
