@import '../../styles/vars';

.description__container {
  border: 1px solid $light_border;
  border-radius: $border-radius;
  background: #fafbfc;

  > div {
    border: 0;
    background: transparent;
    border-radius: 0;
    box-shadow: none;
  }

  .ql-container.ql-snow {
    border: 0;
  }
  .ql-toolbar.ql-snow {
    border: 0;
  }

  .ql-toolbar.ql-snow {
    max-height: 0;
    transition: all $animation-timing;
    overflow: hidden;
    opacity: 0;
    padding: 0;
  }
}

.description__container--open {
  border: 1px solid $active_color;

  padding: 2px;

  .ql-toolbar.ql-snow {
    max-height: 100px;
    opacity: 1;
  }
}

.description__container--non-edit {
  padding: 4px;
}

.desc__editor {
  padding: 8px;
}

.description__title--form {
  margin-bottom: 4px;
  color: #6b778c;
  font-size: 12px;
}
