@import '../../styles/vars';

.teams__container {
  margin-top: $top-nav-height;
  display: flex;
  flex-direction: row;
  width: 100%;

  > div {
    flex: 1;
  }
}

.teams__nav {
  width: 200px;
  height: 100%;
  background: $accout_nav;
  padding: 16px;
  position: fixed;

  > span {
    margin-bottom: 10px;
    font-size: 16px;
    display: block;
    font-weight: bold;
  }
}

.teams__nav--sub {
  padding-left: 10px;
  max-height: 0;
  overflow: hidden;
  transition: max-height $transition-timing;

  > span {
    margin-bottom: 10px;
    font-size: 15px;
    display: block;
  }
}

.teams__nav--open {
  max-height: 200px;
}

.team__body {
  padding: 40px 20px;
  width: 70%;
  margin: 0 auto;
  padding-left: 200px;
}
