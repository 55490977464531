@import './theme.scss';

$small-padding: 8px;
$padding: 16px;
$other-padding: 20px;
$border-radius: 4px;

$animation-timing: 0.25s ease-in-out;

$text-small: 13px;
$text-medium: 15px;
$text-large: 20px;
$text-huge: 40px;

$size-small: 10px;
$size-medium: 15px;
$size-large: 20px;
$size-huge: 40px;

$transition-timing: 0.3s ease-in-out;

$left-nav-width-mobile: 160px;
$left-nav-width: 240px;
$top-nav-height: 56px;
$top-nav-height-sub: 56px;

$small-split: 440px;
$medium-split: 500px;
$large-split: 600px;

$small-screen: 440px;
$medium-screen: 600px;
$medium-large-screen: 1024px;
$large-screen: 1366px;
$x-large-screen: 1700px;
$huge-screen: 1920px;

//  use :
//  @include x-large-screen {
//    display: none;
//  }

@mixin small-screen {
  @media (max-width: $small-screen) {
    @content;
  }
}

@mixin medium-screen {
  @media (min-width: $medium-screen) {
    @content;
  }
}

@mixin medium-large-screen {
  @media (min-width: $medium-large-screen) {
    @content;
  }
}

@mixin medium-large-screen-max {
  @media (max-width: $medium-large-screen) {
    @content;
  }
}

@mixin medium-screen-max {
  @media (max-width: $medium-screen) {
    @content;
  }
}

@mixin large-screen {
  @media (min-width: $large-screen) {
    @content;
  }
}

@mixin x-large-screen {
  @media (min-width: $x-large-screen) {
    @content;
  }
}

@mixin huge-screen {
  @media (min-width: $huge-screen) {
    @content;
  }
}
