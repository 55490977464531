@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?y640ha');
  src: url('../fonts/icomoon.eot?y640ha#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?y640ha') format('truetype'),
    url('../fonts/icomoon.woff?y640ha') format('woff'),
    url('../fonts/icomoon.svg?y640ha#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: auto;
}
