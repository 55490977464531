@import '../../styles/vars';

.issue__comments--name {
  font-weight: bold;
  align-items: center;
  display: flex;
}

.issue__comments--avatar {
  margin-right: 5px;
}

.issue__comments {
  border-bottom: 1px solid $light_border;
  padding: 5px 0;
  margin-bottom: 5px;
  position: relative;

  &:last-of-type {
    border-bottom: 0;
  }
}

.issue__commment--body {
  font-size: 16px;
}

.issue__comments-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  align-items: center;
}

.issues__comments--light {
  color: $mid_text;
}

.issue__comments--edit {
  text-align: right;
}

.issue__comments-assets {
  display: flex;
  margin: 10px 0;
}

.issue__comments-assets-child {
  width: 60px;
  height: 60px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navis__comment {
  padding-top: 5px;
}

.navis__comment--top {
  font-size: 12px;
  color: $mid_text;
  display: block;
}

.navis__comment--user {
  margin-bottom: 0px;
}

.navis__comment--date {
  margin-top: 0;
  margin-bottom: 0;
}

.navis__comment--body {
}

// this is horrible but works for now
.naviswork__comment > div > div > div > div > div:nth-child(1) {
  font-size: 11px;
  border-top: 1px solid lightgray;
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
  padding-top: 10px;
  padding-left: 10px;
}

.naviswork__comment > div > div > div > div > div:nth-child(2) {
  font-size: 11px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid lightgray;
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
  padding-left: 10px;
}

.issue__comments-header {
  padding-bottom: $padding;
  border-bottom: 1px solid $light_border;
  margin-bottom: 10px;
  margin-top: 0 !important;
}
