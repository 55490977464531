@import '../../styles/vars';

.sort-table__table--closed {
  position: absolute;
  top: 1px;
  left: -16px;
  height: calc(100% - 2px);
  width: 100%;
  background: white;
  opacity: 0.7;
  pointer-events: none;
  z-index: 1;
}

.sort-table__desc--hidden-tags {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.sort-table__bulk-edit {
  position: absolute;
  width: 80px;
  height: 80px;
  background: white;
  top: 10px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  opacity: 0;
  transition: all $animation-timing;

  @include medium-screen {
    height: 80px;
  }
}

.sort-table__bulk-edit--compact {
  position: absolute;
  width: 80px;
  height: 40px;
  background: white;
  top: 0px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  opacity: 0;
  transition: all $animation-timing;

  @include medium-screen {
    height: 40px;
  }
}

.sort-table__bulk-edit--visible {
  opacity: 1;
  pointer-events: all;
}
