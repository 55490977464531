// if you are wanting to use colors
// please select a apprioiate item from theme.scss
// or add a new one
// dont edit/add here as it will break the theme

$main_blue: #383e48;
$dark_blue: #2f343c;
$dark_dark_blue: #21242a;

$light_blue: #e7f0fa;

$blue: #39abff;
$blue_pastel: #66ccff;
$green_pastel: #33cc99;
$red_pastel: #e04848;

$black: #000000;
$white: #ffffff;

$grey_light: #9a9fa7;
$grey_dark: #2f343c;
$grey_pale: #f2f5f9;
$grey_ultralight: #babdc4;
$grey_white: #dfe0e5;
$grey_lighest: #f6f8f9;
$grey_medium: #62676f;

$trans-background: rgba(9, 30, 66, 0.54);
