@import '../../styles/vars';

.link__wrapper {
  a {
    color: $link_light;
    display: block;
    // text-shadow: 1px 1px #00000799;
    display: flex;
    align-items: center;

    &:hover {
      color: $link_light;
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }
  }
}

.link__wrapper--dark {
  a {
    color: $link_dark;
    text-shadow: none;

    &:hover {
      color: $link_dark;
    }
  }
}

.link__wrapper--line {
  a {
    display: inline;
  }
}

.link__wrapper--bright {
  a {
    color: $link_bright_txt;
    text-shadow: none;

    &:hover {
      color: $link_bright_txt;
    }
  }
}
