@import '../../styles/vars';

.button__container {
  padding: 4px 10px;
  // background: $button_normal;
  color: white;
  border-radius: $border-radius;
  border: 0;
  cursor: pointer;
  font-size: 14px;
  // transition: background $animation-timing;
  position: relative;
  overflow: hidden;

  @include medium-screen {
    padding: 6px 15px;
  }

  &:hover {
    background: $button_hover;
  }

  // > span {
  //   margin-right: 10px;
  // }

  &:active,
  &:focus {
    outline: 0;
  }
}

.button--space {
  margin: 0 8px;
}

.button--center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button--subtle {
  background: $button_subtle;
  color: $grey_dark;

  &:hover {
    background: $button_subtle_hover;
  }
}

.button--danger {
  background: $button_danger;

  &:hover {
    background: $button_danger_hover;
  }
}

.button__group {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;

  > button,
  > span {
    margin-left: $padding;
    margin-bottom: 0;
  }
}

.button__group--left {
  > button,
  > span {
    margin-right: $padding;
    margin-left: 0;
  }
}

.button__group--between {
  justify-content: space-between;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.button--loading:after {
  content: '';
  background: inherit;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.button--loading:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border-top: 2px solid $button_loading;
  border-right: 2px solid transparent;
  animation: spinner 0.6s linear infinite;
  z-index: 2;
}

.button--disabled {
  background: $button_disabled;
  cursor: not-allowed;

  &:hover {
    background: $button_disabled;
  }
}

.button__group--center {
  justify-content: center;
}

.button--hideOnMobile {
  display: none;

  @include medium-screen {
    display: block;
  }
}
