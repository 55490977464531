.work-space__buttons {
  display: flex;
  justify-content: space-between;
  flex: 1;
  flex-direction: row-reverse;
}

.workspace__text-title {
  display: flex;
  padding: 20px;
  justify-content: center;

  h4 {
    margin-top: 0;
    flex: 1;
    text-align: center;
  }
}

.workspace__issues-section {
  width: 100%;
  padding-bottom: 60px;
}

.workspace__setting-section {
  width: 100%;
  flex-direction: column;
}
