@import '../../styles/vars';

.search-box__container {
  position: relative;

  > div {
    // atlas hack
    border-radius: $border-radius;
    overflow: hidden;
    border: 0;
  }

  input {
    // atlas hack
    line-height: 1;
    font-size: 13px !important;
    padding-left: 16px;

    &::-webkit-input-placeholder {
      font-size: 13px;
    }
    &::-moz-placeholder {
      font-size: 13px;
    }
    &::-ms-input-placeholder {
      font-size: 13px;
    }
    &::placeholder {
      font-size: 13px;
    }
  }
}

.search-box__container--block {
  &:before {
    // adds a little padding so stuff can underflow with put pushing agianst
    content: '';
    width: 10px;
    height: 100%;
    background: red;
    position: absolute;
    left: -10px;
    top: 0;
    background-color: $mid_header_bg;
  }
}

.search-box__icon {
  position: absolute;
  right: 8px;
  top: 7px;
  color: black;
  color: $mid_text;
}

.search-box__container--float {
  float: right;
  position: relative;
  top: -5px;

  input {
    // atlas hack
    height: 20px !important;
  }

  .search-box__icon {
    position: absolute;
    right: 0;
    top: 0;
  }
}
