.bi__modal {
  height: 80vh;
  width: 100%;
  padding: 20px 0;

  iframe {
    width: 100%;
    height: 100%;
  }
}
