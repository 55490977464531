@import '../../styles/vars';

.stat__container-child {
  flex: 1 0 21%;
  margin-bottom: $size-huge;
}

.stat__name {
  font-size: $text-medium;
}

.stat__value {
  font-size: $text-huge;
  font-weight: 500;
}
