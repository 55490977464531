@import '../../styles/vars';

.project-nav__container {
  background: $mid_header_bg;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: $top-nav-height-sub;
  align-items: center;
  position: fixed;
  top: $top-nav-height;
  z-index: 2;
}

.project-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  white-space: nowrap;
  padding: 10px 16px;
  height: 100%;
}

.project-nav__top-container {
  margin-left: $padding;
  display: flex;
}

.project-nav__title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.project-nav__title {
  font-weight: bold;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: $top_bar_txt;
}

.project-nav__title--overflow {
  max-width: 300px;
  // overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-bottom: 4px;
  position: relative;
  padding-right: 10px;
  font-size: 14px;
}

.project-nav__blurb {
  font-size: 11px;
  margin: 0;
  color: $top_bar_project_desc;
  text-transform: capitalize;
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.project-nav__header {
  background: $project_header_bg;
  padding: $size-medium;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;

  p {
    margin-bottom: 0;
    font-weight: bold;
    font-size: 16px;
  }
}

.project-nav__search {
  padding: $size-medium;
  background: $project_nav_search_bg;
  position: relative;
}

.project-nav__top-click {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: white;
}

.project-nav__drop-down {
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 200vw;
  overflow: hidden;
  z-index: 10;
  transform: translateX(-400px);
  transition: all 0.3s 0.1s ease-in-out;
  display: flex;
  pointer-events: none;
}

.project-nav__drop-down--open {
  transform: translateX(0);
  pointer-events: all;
}

.project-nav__drop-down--left {
  min-width: 300px;
  max-width: 300px;
  color: $dark_text;
  background-color: $project_menu_bg;
  height: 100vh;
  display: flex;
  flex-direction: column;

  @include medium-screen {
    min-width: 400px;
    max-width: 400px;
  }
}

.project-nav__drop-down--right {
  width: 100%;
  cursor: pointer;
  background: $project_open_bg;
  opacity: 0;
  transition: all 0.3s 0.35s ease-in-out;
}

.project-nav__drop-down--right--open {
  opacity: 1;
}

.project-nav__item-container {
  padding: $size-medium;
  padding-bottom: 0;
  cursor: pointer;
  transition: all $animation-timing;

  &:hover {
    background: $project_nav_hover;

    .project-nav__sub-menu {
      opacity: 1;
    }
  }
}

.project-nav__item-sub {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $light_border;
  align-items: center;
  padding-bottom: $size-medium;
  height: 60px;
}

.project-nav__item {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-transform: capitalize;
  overflow: hidden;
}

.project-nav__item--active {
  cursor: not-allowed;
  color: $active-color;
}

.project-nav__item--clip {
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
}

.project-nav__desc {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.project-nav__image {
  background: $project_nav_image_bg;
  border-radius: $border-radius;
  margin-right: $padding;
  height: 40px;
  max-width: 40px;
  min-width: 40px;
  overflow: hidden;
  display: none;
  justify-content: center;
  align-items: center;
  background-size: cover;

  @include medium-screen {
    display: flex;
  }
}

.project-nav__links {
  margin-right: $padding;
  display: none;

  @include medium-screen {
    display: block;
  }

  > * {
    padding-left: 10px;

    @include medium-large-screen {
      padding-left: $padding * 2;
    }
  }
}

.project-nav__top-search > .search-box__container.search-box__container--block {
  pointer-events: auto;
}

.project-nav__top-search:not(.search-box__container.search-box__container--block) {
  pointer-events: none;
}

.project-nav__top-search {
  position: absolute;
  top: 0;
  left: -10px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  justify-content: flex-end;

  @include medium-screen {
    justify-content: center;
    left: 0;
  }

  > div {
    width: 125px;

    @include medium-large-screen {
      width: 350px;
    }
  }
}

.project-nav__item-cell {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  overflow: hidden;
}

.project-nav__item-container--scroll {
  overflow-y: overlay;
  padding-bottom: 60px;
}

.project-nav__edit-icon {
  position: absolute;
  right: -12px;
  top: -2px;
}

.project-nav__drop-down--text {
  font-size: 9px;
  font-style: italic;
  color: $mid_text;
  padding-top: 2px;
}

.project-nav__item--title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $light_border;
  margin: 0 16px;
  padding: 16px 0;
}

.project-nav__item--title {
  font-size: 14px;
  font-weight: bold;
}
