@import '../../styles/vars';

.modal-format__body {
  max-height: 70vh;
  box-shadow: 0px 9px 14px -14px rgba(0, 0, 0, 0.2);
  overflow-x: auto;
  display: flex;

  > div {
    flex: 1;
  }
}

.modal-format__footer {
  padding-top: 15px;
  display: flex;
  justify-content: flex-end;
}

.modal-format__body--no-footer {
  height: auto;
  box-shadow: none;
}
