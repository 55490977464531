@import '../../styles/vars';

.login__buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;

  > div {
    margin-top: 0;
  }
}

.login__out-buttons {
  color: white;
  text-decoration: underline;
  margin-top: 15px;
  text-align: center;

  a {
    color: $mid_text;
  }
}

.login__sso-buttons {
  color: $mid_text;

  margin-top: 8px;
  text-align: center;

  a {
    color: $mid_text;
    text-decoration: underline;

    :hover {
      color: #2f343c;
    }
  }

  .login__sso-link {
    color: $mid_text;
    text-decoration: underline;
    cursor: pointer;
  }

  .login__sso-link:hover {
    color: #2f343c;
  }
}

.loading__container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  z-index: 99;
  transition: opacity $transition-timing;
}

.loading__container--animation {
  opacity: 0;
  pointer-events: none;
}

.loading__icon {
  width: 80px;
  height: 80px;
  position: relative;
  top: 40vh;
  animation: rotating 1s infinite ease-in-out;
}

@-webkit-keyframes rotating {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}
