@import '../../styles/vars';

$modal-height: 85vh;

.asset-viewer__container {
  margin-bottom: -2px;
  background: black;
  // min-height: $modal-height;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: $border-radius;
  overflow: hidden;

  @include medium-screen {
    font-size: 13px;
  }
}

.asset-viewer__bottom {
  background: $asset_bottom;
  padding: $padding;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
  display: flex;

  > a {
    background: gray;
  }
}

.asset-viewer__bottom-item {
  height: 90px;
  margin-right: 20px;
  cursor: pointer;
}

.asset-viewer__body {
  // display: flex;
  // justify-content: space-between;
  // flex: 1;
  display: grid;
  grid-template-columns: 1fr auto;
  min-height: 60vh;
}

.asset-viewer__info {
  width: 240px;
  background: $asset_side_bar;
  color: $light_text;
  padding: $padding;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  word-break: break-all;
  display: none;

  @include medium-screen {
    display: flex;
  }
}

.asset-viewer__body-window {
  // align-items: center;
  // justify-content: center;
  place-self: center;
  // display: flex;
  // flex: 1;

  > img {
    max-width: 100%;
    max-height: calc(#{$modal-height} - 158px);
    width: auto;
    height: auto;
  }
}

.asset-viewer__buttons {
  > span {
    border-top: 1px solid $mid_border;
    display: block;
    padding: 16px 0;

    &:last-of-type {
      padding-bottom: 0;
    }
  }
}

.asset-viewer__thumb-text {
  color: $mid_text;
  width: 100px;
  overflow: hidden;
  white-space: normal;
  font-size: 12px;
  text-align: center;
  margin: 5px 0;
}

.asset-viewer__thumb-text--active {
  color: $white;
}
