@import '../../styles/vars';

.container__top-level {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.container__content-wrapper {
  flex: 1;
  position: relative;
  z-index: 0;

  @include medium-large-screen-max {
    margin-left: 0;
  }
}

.container__content-wrapper--admin {
  padding: 20px;
}

.container__left-wrapper {
  height: calc(100% - ($top-nav-height + $top-nav-height-sub));
  display: flex;
  margin-top: $top-nav-height + $top-nav-height-sub;
}

.container__no-projects {
  display: block;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.container__left-wrapper--admin {
  margin-top: $top-nav-height + $top-nav-height-sub;
  width: 100%;
  padding: 40px;

  > div {
    flex: 1;
  }
}

.container__left-hover-panel {
  height: calc(100% - ($top-nav-height + $top-nav-height-sub));
}

.container__left-hover-sidebar {
  height: calc(100% - 64px);
}

.container__confirm-account {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-direction: column;
}

// overwrite toast colors

.Toastify__toast--success {
  background-color: #21ab7a !important;
}

.Toastify__toast--error {
  background-color: #d23a3a !important;
}

.Toastify__toast--info {
  background-color: #00b8e6 !important;
}

.Toastify__toast--warning {
  background-color: #ffaa15 !important;
}

.container__leftNavMobileCover {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  left: 0;
  top: $top-nav-height + $top-nav-height-sub;
  z-index: 9;
  display: none;
}

.container__leftNavMobileCover--open {
  display: block;

  @include medium-screen {
    display: none;
  }
}

.custom-scroll::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
  /* scrollbar-width: thin; */
  margin-left: 1rem; /* width of the entire scrollbar */
}

.custom-scroll::-webkit-scrollbar-track {
  background: rgb(241 245 249); /* color of the tracking area */
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: rgb(203, 213, 225); /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: white; /* creates padding around scroll thumb */
}

.custom-scroll-dark::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
  /* scrollbar-width: thin; */
  margin-left: 1rem; /* width of the entire scrollbar */
}

.custom-scroll-dark::-webkit-scrollbar-track {
  background: #2f343c; /* color of the tracking area */
  border-radius: 20px;
}

.custom-scroll-dark::-webkit-scrollbar-thumb {
  background-color: rgb(203, 213, 225); /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: white; /* creates padding around scroll thumb */
}
