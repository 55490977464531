.filters__container {
  h4 {
    margin-top: 5px;
    margin-bottom: 10px;
  }
}

.filters__creation-date {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.DayPicker-wrapper {
  outline: none;
}

.DayPicker-Months:focus {
  outline: none;
}

.DayPicker-Month:focus {
  outline: none;
}

.rdrMonth {
  width: 100% !important;
}
