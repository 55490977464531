@import '../../styles/vars';

$total-height: $top-nav-height + $top-nav-height-sub;

.left-nav__container {
  // flex: 1;
  // width: $left-nav-width;
  // height: 100%;

  // TESTING
  // height: calc(100% - 64px);
  height: calc(100% - #{$total-height});

  // position: fixed;
  // flex-direction: column;
  // top: $total-height;
  overflow-y: auto;
  background: $left_nav_bg;
  // padding-bottom: 50px;
  left: 0;

  @include medium-large-screen-max {
    left: -$left-nav-width;
  }
}

.left-nav__container--open {
  left: 0;
  // z-index: 10;
  z-index: 200;
  position: fixed;
  min-width: 16rem;
}

.left-nav__container--hover {
  height: calc(100% - 64px);
}

.left-nav__workspace {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 67px;
  margin-left: 16px;
  padding-right: 6px;

  button {
    padding-right: 0;
  }

  span {
    padding-top: 2px;
  }

  h4 {
    color: white;
  }
}

.left-nav__item {
  padding: $small-padding;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: background $animation-timing;
  height: 48px;
  color: $left_nav_txt;
  border-radius: $border-radius;
  margin-left: $small-padding;
  margin-right: $small-padding;
  position: relative;

  &:hover:not(.left-nav__item--active) {
    background: $left_nav_hover;
  }

  &:hover {
    .left-nav__item--icon {
      opacity: 1;
    }
  }
}

.left-nav__item--inner {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.left-nav__item--text {
  display: flex;
  align-items: center;
  max-width: 100%;
  padding-right: 35px;

  > span {
    margin-right: 10px;
    width: 20px;
    height: 20px;
  }
}

.left-nav__item--icon {
  opacity: 0;
  border-radius: $border-radius;
  padding: 2px;
  transition: background $animation-timing;
  position: absolute;
  right: 10px;
  display: flex;

  &:hover {
    background: $left_nav_icon_hover;
  }
}

.left-nav__item--active {
  background: $left_nav_active_bg;
  font-weight: bold;
}

.left-nav__separator {
  border-top: 1px solid $grey_light;
  margin: 0 6px 0 $padding;
}

.left-nav__newWorkspace {
  // position: fixed;
  // bottom: 10px;
  // left: 10px;
  height: 40px;
  // width: 100%;
  width: calc(100% - 8px);
  // width: $left-nav-width - 6px;
  background: $top_header_bg;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: white;
  border-radius: $border-radius;
  display: none;
  margin-left: 8px;
  // margin-right: 8px;

  @include medium-screen {
    display: flex;
  }
}

.leftNav__mobileCover {
}
