@import '../../styles/vars';

$total-height: $top-nav-height + $top-nav-height-sub;

.account-nav__container {
  margin-top: $top-nav-height;
  display: flex;
  flex-direciton: row;
  width: 100%;
}

.account-nav__left {
  width: $left-nav-width-mobile;
  flex-direction: column;
  background: $left_nav_bg;
  height: 100%;
  padding: 0 $small-padding;
  position: fixed;
  overflow-y: auto;
  height: calc(100% - 56px);
  padding-bottom: $small-padding;

  @include medium-screen {
    width: $left-nav-width;
  }
}

.account-nav__item {
  padding: $small-padding;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background $animation-timing;
  height: 48px;
  border-radius: $border-radius;
  position: relative;
  color: $left_nav_txt;
}

.account-nav__item--active {
  background: #62676f;
  font-weight: bold;
}

.account-nav-right {
  flex: 1;
  padding: 20px 20px;
  margin-left: $left-nav-width-mobile;

  @include medium-screen {
    padding: 40px 200px;
    margin-left: 240px;
  }
}

.account-nav__item--header {
  cursor: auto;
  font-weight: 900;
  font-size: 16px;
}

.account-nav__item--pad-left {
  padding-left: 10px;
}
