@import '../../styles/vars';

.dragger__container {
  flex: 1;
  height: 100%;
  position: relative;
}

.dragger__container--active {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 3px dashed gray;
  padding: 5px;
  z-index: 1;
}

.dragger__container--active-offset {
  position: absolute;
  top: -4px;
  left: -8px;
  width: calc(100% + 16px);
  height: 100%;
  border: 3px dashed gray;
  padding: 5px;
  z-index: 1;
  border-radius: $border-radius;
  cursor: copy;
}
