@import '../../styles/vars';

.project-edit__table--no-mem {
  text-align: center;
  padding: 10px;
}

.project-edit__button {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
}

.edit-members__title {
  margin-bottom: 10px;
}

.edit-members__add-new {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.edit-members__add-new--member {
  width: 70%;
}

.edit-members__add-new--role {
  width: 20%;
}

.edit-members__add-new--button {
  padding-top: 29px;
}
