@import '../../styles/vars';

.external-wrapper__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  //background-image: url('/bg/bg_8.jpg');
  background-size: cover;
  padding: $size-large;
  background-attachment: fixed;
  flex-direction: column;
  min-height: 100vh;
}

.external-wrapper__inner-container {
  min-width: 250px;
  width: 100%;
  max-width: 400px;
  flex-direction: column;
  border-radius: $border-radius;
  background-color: #fffffff2;
  padding: 30px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.26);
}

.external-wrapper__inner-container--big {
  max-width: 800px;
}

.external-wrapper__logo {
  margin-bottom: $size-large;
  text-align: center;

  img {
    max-width: 300px;
    padding: 0 30px;
  }
}
