@import '../../styles/vars';

.check-box__container {
  svg {
    color: white;
    fill: white;
  }

  rect {
    stroke: rgb(223, 225, 230);
    stroke-width: 1px;
    transition: stroke $animation-timing;
  }
}

.check-box__container--active {
  svg {
    color: $link_bright_txt;
  }

  rect {
    stroke: $link_bright_txt;
  }
}

.check-box__wrapper {
  display: grid;
  grid-template-columns: auto 1fr;
  place-items: center;
  justify-items: start;
  grid-gap: 0.5rem;

  // display: flex;
  // align-items: center;
  cursor: pointer;

  // p {
  //   margin: 0;
  //   margin-bottom: 6px;
  // }
}
