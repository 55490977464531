@import '../../styles/vars';

.project-edit__member-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 20px;
}

.project-edit__container {
  height: calc(100% - 60px);
  box-shadow: 0px 9px 14px -14px rgba(0, 0, 0, 0.2);
  overflow-x: auto;

  [role='tab'] {
    color: $dark_text;

    &:hover {
      color: $link_bright_txt;
    }

    > span {
      background-color: $link_bright_txt;
    }
  }
}

.project-edit__member-container--teams {
  max-height: none;
  box-shadow: none;
}

.project-edit__select-cell {
  width: 150px;
}

.project-edit__delete-cell {
  width: 50px;
  text-align: center;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    color: $danger;
  }
}

.project-edit__dis {
  text-transform: uppercase;
}

div[role='tab']:focus {
  box-shadow: none;
}

.project-edit__table {
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 30px;

  thead td {
    border-bottom: 1px solid $light_border;
    font-weight: bold;
    text-align: left;
    padding: 5px 10px 5px 0;
  }
}

.project-edit__member-row {
  td {
    padding: 5px 10px 5px 0;
    border-bottom: 1px solid $light_border;
    white-space: normal !important;
  }
}
