@import '../../styles/vars';

.asset-cell__info-container {
  p {
    margin: 0;
  }
}

.asset-cell__info {
  color: $mid_text;
}
