@import '../../styles/vars';

.uploadModal__container {
  // border: 2px dashed gray;
  padding: 10px;
  // margin-bottom: 30px;
  // margin-bottom: 60px;
  text-align: center;
  position: relative;

  svg {
    font-size: 50px;
  }

  h4 {
    margin-top: 10px;
  }
}

.imageUploader__hiddenInput {
  display: none;
}

.uploadModal__container--active {
  border: 2px dashed $blue;

  svg > g {
    fill: $blue;
  }

  * {
    pointer-events: none;
  }
}

.uploadModal__cam {
  margin: 20px;
  overflow: hidden;
}

.uploadModal__camVideo {
  margin-bottom: 20px;
  max-height: 55vh;
}

.uploadModal__info {
  display: flex;
  justify-content: center;
  align-items: center;
}

.uploadModal__info--highlight {
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
