.modal__close {
  position: absolute;
  right: 9px;
  top: 5px;
  color: black;
  font-weight: 900;
  font-size: 14px;
  cursor: pointer;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  z-index: 22;
}

.modal__heading {
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex: 0 0 auto;
  transition: box-shadow 200ms ease 0s;
  padding: 10px 0px;

  h1 {
    margin-bottom: 0;
    font-size: 16px;
  }
}

.modal__heading--no-text {
  padding: 0;
}
