@import '../../styles/vars';

.project-nav__sub-menu {
  cursor: pointer;
  border-radius: $border-radius;
  padding: 5px;
  min-width: 35px;
  opacity: 0;
  color: $mid_text;

  &:hover {
    color: $link_bright_txt;
  }
}

.project-menu__padding {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.project-menu__items {
  transform-origin: top right;
  position: absolute;
  right: 70%;
  // top: -39%; // For 2 menu items
  top: 0;
  display: grid;
  align-items: center;
  width: 6rem;
  border-radius: 0.375rem;
  background-color: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.12);
  z-index: 2;
}
.project-menu__item3 {
  line-height: 1.25rem;
  display: block;
  border: none;
  width: 100%;
  z-index: 0;
  cursor: pointer;
}
