@import '../../styles/vars';

.feedback__image {
  overflow: hidden;
  max-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
  }
}

.feedback__location {
  overflow: hidden;
  text-overflow: ellipsis;
}

.feedback__cell {
  white-space: normal;
}
