@import '../../styles/vars';

.admin__container {
  // flex: 1;
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  max-height: 100vh;
}

.admin__container--child {
  margin-top: $top-nav-height;
  padding: 40px 20px;
  overflow-y: auto;
  display: grid;
  grid-template-rows: auto 1fr auto;
  max-height: 100vh;
}
