@import '../../styles/vars';

.profile__container {
  margin-top: $top-nav-height;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.profile__nav {
  width: 200px;
  height: 100%;
  background: $accout_nav;
  padding: 20px;

  > span {
    margin-bottom: 10px;
    font-size: 17px;
    display: block;
  }
}

.profile__body {
  padding: 20px;
  width: 70%;
  margin: 0 auto;
}

.profile__sub--container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.profile__sub--item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid lightgray;
  padding: 5px 0;
  cursor: pointer;
  word-break: break-all;

  .big-flex {
    flex: 1;
    padding-left: $padding;
  }

  h5,
  p {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.profile__sub--title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
