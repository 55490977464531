@import '../../styles/vars';

#loader {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 99;
  transition: opacity $transition-timing;
}

#loader_spin {
  animation: spin 1.5s linear infinite;
  display: block;
  transform-origin: center center;
  width: 80px;
  height: 80px;
}

@keyframes spin {
  0% {
      transform: rotate3d(0);
  }
  100% {
      transform: rotateY(360deg);
  }
}

#loader_spin_div {
  pointer-events: none;
  margin: auto;
  margin-top: 30vh;
  transform: translateZ(100px) perspective(300px);
  transform-style: preserve-3d;
  
}
/*
@media (min-width: 440px) {
  #loader_spin_div {
      width: 27vh;
  }
}
*/

.loading__container--animation {
  opacity: 0;
  pointer-events: none;
}
