@import '../../styles/vars';

$height: $top-nav-height + $top-nav-height-sub;

.issue__container {
  overflow-y: auto;
  scroll-behavior: smooth;
  position: relative;
  max-width: 100vw;
  min-width: 100vw;
  padding-bottom: 10px;
  min-width: none;
  max-width: none;

  @include medium-screen {
    width: 100%;
    min-width: 440px;
    max-width: 600px;
    //   max-width: $small-split;
    //   min-width: $small-split;
  }

  // @include large-screen {
  //   max-width: $medium-split;
  //   min-width: $medium-split;
  // }
  // @include x-large-screen {
  //   max-width: $large-split;
  //   min-width: $large-split;
  // }

  // TODO: Multiple versions for mobile vs desktop

  h5 {
    margin-bottom: 8px;
    margin-top: 16px;
    font-size: 12px;

    @include medium-screen {
      margin-bottom: $size-small;
      margin-top: $other-padding;
      font-size: 13px;
    }
  }
}

.issue__top-section {
  position: relative;
  min-height: 70px;
}

.issue__image {
  background: black;
  text-align: center;
  width: 100%;

  img {
    max-height: 40vh;
  }
}

.issue__padding-container {
  padding: 0 $padding;
  position: relative;
}

.issues__title {
  flex: 1;

  p {
    margin-bottom: 3px;
    margin-left: 1px;
    word-break: break-all;
  }
}

.issues__top-box--gradient {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.8) 40%,
    rgba(0, 0, 0, 0) 100%
  );
  padding-bottom: 30px !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: white;

  h5,
  textarea,
  p {
    color: white;
  }

  h5 {
    font-size: 13px;
  }
}

.issues__top-box {
  padding: 15px 15px 0 15px;
  display: flex;
  justify-content: space-between;

  h5 {
    margin: 0 0 5px 0;
  }
}

.issue__editor {
  border: 1px solid $light_border;
  padding: 0 10px;
}

.issue__attachment-thumb {
  width: 50px;
  height: 50px;
  border-radius: $border-radius;
  margin-right: 10px;
  background-size: cover;
  background-position: center;
}

.issue__attachment-left {
  display: flex;
  align-items: center;
  flex: 1;
  cursor: pointer;
}

.issue__attachment-right {
  text-align: right;
  color: $mid_text;
  opacity: 0;
  transition: all $animation-timing;
  position: absolute;
  top: 13px;
  right: 0;
  background: white;
  padding-left: 10px;
}

.issue__attachment-name {
  max-width: 355px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: $link_bright_txt;
}

.issue__desc-upload {
  color: black;
  transition: all $animation-timing;
  cursor: pointer;

  &:hover {
    color: $active-color;
  }
}

.issue__attachment--add {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: $other-padding;
  border-bottom: 1px solid $light_border;

  .link__wrapper {
    margin-bottom: 10px;
  }
}

.issue__attachments-container {
  display: flex;
  flex-direction: column;
}

.issue__attachment {
  display: flex;
  justify-content: space-between;
  margin-top: $padding;
  align-items: center;
  position: relative;

  &:hover {
    .issue__attachment-right {
      opacity: 1;
    }
  }
}

.issue__not-open {
  padding: 100px $size-large $size-large;
  text-align: center;
  height: 100%;
  // display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: $mid_text;
}

.issue__top-box--title {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
  background: transparent;
  border: 0;
  color: $dark_text;
  font-size: 16px;
  width: 90%;
  text-overflow: ellipsis;
  line-height: 1.4;

  &:focus {
    outline: 0;
  }

  span {
    margin-left: 3px;
    position: relative;
    top: 1px;
  }
}

.issue__top-box--active {
  border: 2px solid $blue_pastel;
  border-radius: $border-radius;
  padding: 5px;
  resize: none;
  overflow: hidden;
}

.issue__container--click {
  position: absolute;
  top: 0;
  left: 0;
  background: black;
  opacity: 0.2;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: not-allowed;
}

.issue__top-box--date {
  color: $mid_text;
}

.issue__split-title {
  padding-bottom: 10px;
  border-bottom: 1px solid $light_border;
  margin-bottom: 10px;
}

.issue__split-title--text {
  color: lightgrey;
  cursor: pointer;
}

.issue__split-title--active {
  color: $dark_text;
}
