@import '../../styles/vars';

.subtasks__container {
  display: flex;
  flex-direction: column;
}

.subtasks__child {
  border-bottom: 1px solid $table-border;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  justify-content: space-between;

  > div {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;

    &:nth-child(2) {
      justify-content: flex-end;
    }
  }
}

.subtasks__child--title {
  margin: 0 0 0 10px;
}

.subtasks__child--cell {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
}

.subtasks__child--input {
  margin: 0 0 0 10px;
  width: 100%;
}

.subTask__delete {
  max-width: 25px;
  cursor: pointer;
  margin-left: 5px;

  &:hover {
    svg,
    path {
      fill: $danger;
    }
  }
}
