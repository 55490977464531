@import './colors.scss';

// text

$light_text: $white;
$mid_text: $grey_light;
$dark_text: $grey_dark;

// link

$link_bright_txt: $blue_pastel;
$link_light: $white;
$link_dark: $grey_dark;

// header

$top_header_bg: $dark_dark_blue;
$mid_header_bg: $dark_blue;

// left nav

$left_nav_bg: $main_blue;
$left_nav_active_bg: $grey_medium;
$left_nav_hover: $grey_dark;
$left_nav_icon_hover: $grey_light;
$left_nav_txt: $white;

$project_header_bg: $light_blue;
$project_nav_search_bg: $grey_white;
$project_nav_hover: $grey_white;
$project_open_bg: $trans-background;
$project_menu_bg: $white;
$project_nav_image_bg: $dark_dark_blue;

// top section

$top_bar_txt: $white;
$top_bar_project_desc: $grey_light;
$top_bar_search_text: $blue;

// dashboard

$dashboard_top_bg: $grey_pale;
$dashboard_active_row: $light_blue;
$dashboard_row_border: $grey_pale;
$dashboard_seen: $grey_white;
$dashboard_placeholder: $grey_white;
$dashboard_hover: $grey_lighest;
$dashboard_main_bg: $white;

// misc

$main_color: $blue;
$active_color: $blue_pastel;
$danger: $red_pastel;
$table-border: $grey_pale;

$dark_border: $grey_dark;
$mid_border: $grey_medium;
$light_border: $grey_white;

// label

$label_hover: $grey_pale;
$label_bg: $grey_white;

// tags

$tag_bg: $grey_medium;
$tag_active_bg: $green_pastel;

// item

$item_desc_bg: $grey_pale;
$item_thumb_bg: $grey_pale;

// asset viwer

$asset_side_bar: $main_blue;
$asset_bottom: $dark_dark_blue;

// button

$button_normal: $blue_pastel;
$button_hover: darken($button_normal, 5);

$button_disabled: $grey_light;

$button_subtle: $grey_white;
$button_subtle_hover: darken($button_subtle, 5);

$button_danger: $red_pastel;
$button_danger_hover: darken($button_danger, 5);

$button_loading: $white;

// notif section
$notif_title: $grey_pale;
$notif_date: $grey_light;
$notif_hover: $grey_pale;
$notif_active: $light_blue;

// account
$accout_nav: $grey_pale;
