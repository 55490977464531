@import '../../styles/vars';

.bulk-edit__container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100px;
  width: 100%;
  background: $project_header_bg;
  z-index: 3;
  display: grid;
  grid-template-columns: auto 1fr;
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  max-height: 0;
  transition: all $animation-timing;
  opacity: 0;
  pointer-events: none;
}

.bulk-edit__container--open {
  max-height: 100px;
  opacity: 1;
  pointer-events: all;
}

.bulk-edit__select-all {
  width: 80px;
  display: grid;
  height: 100%;
  max-height: 100px;
  place-content: center;
}

// .bulk-edit__body {
// }

.bulk-edit__tags {
  > div {
    padding-top: 0;
  }
}

.bulk-edit__sub-container {
  display: grid;
  grid-template-columns: 1fr auto;
  height: 100%;
}
