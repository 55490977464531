@import '../../styles/vars';

.notifications__container {
  color: $dark_text;
  width: 270px;
  max-height: 70vh;
  border-radius: $border-radius;
  overflow: hidden;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.26);
  display: flex;
  flex-direction: column;

  @include medium-screen {
    width: 460px;
  }
}

.notifications__title {
  font-size: 16px;
  font-weight: bold;
  background: $notif_title;
  padding: 10px;
}

.notifications__row {
  padding: 10px;
  text-transform: none;
  transition: all $animation-timing;
  display: flex;
  position: relative;

  &:first-of-type::before {
    border-bottom: 0;
  }

  &:before {
    border-bottom: 1px solid $light_border;
    content: '';
    margin: 0 10px;
    width: calc(100% - 20px);
    height: 1px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:hover {
    background: $notif_hover;
  }
}

.notifications__row--seen {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
}

.notifications__row--cut {
  overflow: hidden;
  flex: 1;
}

.notifications__row--hight-light {
  background: $notif_hover;
}

.notifications__date {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  font-size: 10px;
  color: $notif_date;
  overflow: hidden;
  padding-top: 5px;
}

.notifications__date--name {
  text-overflow: ellipsis;
  padding-right: 15px;
  overflow: hidden;
}

.notifications__container--scroll {
  overflow-y: auto;
}
