@import '../../styles/vars';

.new-comment__container {
  margin-bottom: 10px;
  margin-top: 20px;
  border-radius: $border-radius;
  background: #fafbfc;

  > div {
    border: 0;
    background: transparent;
    border-radius: 0;
    box-shadow: none;
  }

  .ql-container.ql-snow {
    border: 0;
  }
  .ql-toolbar.ql-snow {
    border: 0;
  }

  .ql-toolbar.ql-snow {
    max-height: 0;
    transition: all $animation-timing;
    overflow: hidden;
    opacity: 0;
    padding: 0;
  }
}

.new-comment__files {
  position: relative;
  bottom: 0;
  width: 100%;
  display: flex;
}

.new-comment__files-inner {
  border: 1px solid transparent;
  margin: 10px 0 10px 10px;
  cursor: pointer;
  transition: all $animation-timing;
  border-radius: $border-radius;

  &:hover {
    border: 1px solid $danger;
  }
}

.new-comment__files--move {
  height: 0;
}

.new-comment__container-inner {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  // overflow: hidden;
  height: auto;
  transition: all $animation-timing;
  border-radius: $border-radius;

  // test
  border: 1px solid $light_border;
  background: transparent;
  box-shadow: none;
}

.new-comment__container-inner--open {
  border: 1px solid $active_color;

  padding: 4px;

  .ql-toolbar.ql-snow {
    max-height: 100px;
    opacity: 1;
  }
}

.new-comment__rte {
  flex: 1;
  width: 230px;

  @include medium-screen {
    width: 300px;
  }

  > div {
    border: 0;
    background: transparent;
    border-radius: 0;
    box-shadow: none;
  }
}

.new-comment__rte--meiton {
  position: absolute;
  top: 0;
  height: 2px;
  width: 200px;
  background: red;
  left: 0;

  > div {
    background: transparent;
    box-shadow: none;
    border: 0;

    > div {
      background: white;
      border: 1px solid #fcfcfc;
    }
  }
}

.new-comment__buttons {
  height: 47px;
  display: flex;
  align-items: center;
  padding-right: 10px;

  button {
    margin-left: 10px;
  }
}

.new-comment__upload {
  color: black;
  transition: all $animation-timing;
  cursor: pointer;
  padding-top: 2px;

  &:hover {
    color: $active-color;
  }
}

.new-comment__container--bottom-pad {
  margin-bottom: 40px;
}

.new-comment__metions-container {
  position: absolute;
  top: -10px;
  left: 0;
  width: 100%;
}

.new-comment__metions-inner {
  border: 1px solid #dedede;
  border-radius: $border-radius;
  background: $item_desc_bg;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 250px;
}

.new-comment__mentions-row {
  padding: 5px;
  cursor: pointer;

  &:hover {
    background: $link_bright_txt;
  }
}

.global__comment-mention {
  background: $link_bright_txt;
  color: white;
}

// editor

.editor {
  padding: 0;
  margin: 0;
}

.editor .public-DraftEditor-content {
  padding: 6px;
}

.editor__buttons {
  display: flex;
  max-height: 0;
  transition: all $animation-timing;
  opacity: 0;
  pointer-events: none;

  button {
    background: transparent;
    border: 0;

    &:hover {
      color: $link_bright_txt;
    }
  }
}

.editor__buttons--visible {
  max-height: 100px;
  opacity: 1;
  pointer-events: all;
}

.red {
  background: red;
  color: red;
}
