@import '../../styles/vars';

.landing-page__container {
  width: 1100px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 120px;
  padding-bottom: 100px;
  position: relative;
}

.landing-page__image {
  width: 100%;
  background-size: cover;
  background-position: center;
  background-color: lightgray;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  color: $mid_text;

  &:hover {
    color: $link_bright_txt;
  }

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
}

.landing-page__cell {
  text-align: center;
  cursor: pointer;
  width: 48%;
  margin-right: 2%;
  color: $dark_text;

  @include medium-screen {
    width: 23%;
  }

  &:hover {
    text-decoration: none;

    h4,
    p {
      color: $link_bright_txt;
    }
  }

  h4 {
    margin-top: 10px;
  }

  p {
    margin-top: 5px;
  }
}

.landing-page__rows {
  display: flex;
  flex-wrap: wrap;
}

.landing-page__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;

  h1 {
    margin-bottom: 0;
  }
}

.landing-page__no-projects--sticker {
  font-size: 80px;
}

.landing-page__no-projects {
  padding-top: 60px;
  text-align: center;
}
