@import '../../styles/vars';

.item-history__container {
  // max-height: calc(100% - 20px);
  max-height: 70vh;
  overflow-y: auto;
}

.item-history__search {
  background: lightgray;

  padding: 10px;
  margin-bottom: 20px;
}

.history__pagination {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  margin-top: 20px;

  li {
    flex: 1;
    margin-top: 0;
    text-align: center;

    a {
      color: lightgray;
      outline: none;
      cursor: pointer;
    }
  }

  .history__active {
    a {
      color: $link_bright_txt;
    }
  }
}
