@import '../../styles/vars';

.sort-table__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 0;
  justify-content: space-between;
  // border-bottom: 1px solid $light_border;
  cursor: pointer;
  transition: all $animation-timing;
  background: $dashboard_main_bg;
  height: 80px;
  position: relative;

  @include medium-screen {
    padding: 10px 0;
    height: 100px;
  }

  &:before {
    content: '';
    width: 100%;
    height: 1px;
    background: $light_border;
    position: absolute;
    bottom: 0;
    right: 16px;
  }

  &:after {
    content: '';
    width: 164px;
    height: 1px;
    background: white;
    position: absolute;
    bottom: 0;
    left: 0;

    @include medium-screen {
      width: 218px;
    }
  }

  &:hover:not(.sort-table__table--active) {
    background: $dashboard_hover;

    .sort-table__bulk-edit {
      background: $dashboard_hover;
    }
  }

  > div {
    margin-left: $padding;

    &:last-of-type {
      margin-right: $padding;
    }
  }
}

.sort-table__container--compact {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 0;
  justify-content: space-between;
  // border-bottom: 1px solid $light_border;
  cursor: pointer;
  transition: all $animation-timing;
  background: $dashboard_main_bg;
  height: 40px;
  position: relative;

  @include medium-screen {
    padding: 10px 0;
    height: 40px;
  }

  &:before {
    content: '';
    width: 100%;
    height: 1px;
    background: $light_border;
    position: absolute;
    bottom: 0;
    right: 16px;
  }

  &:after {
    content: '';
    width: 60px;
    height: 1px;
    background: white;
    position: absolute;
    bottom: 0;
    left: 0;

    @include medium-screen {
      width: 80px;
    }
  }

  &:hover:not(.sort-table__table--active) {
    background: $dashboard_hover;

    .sort-table__bulk-edit {
      background: $dashboard_hover;
    }
  }

  > div {
    margin-left: $padding;

    &:last-of-type {
      margin-right: $padding;
    }
  }
}

.sort-table__split {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}

.sort-table__split--top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sort-table__split--bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;

  .tag__container {
    margin-bottom: 0;
  }
}

.sort-table__seen {
  display: flex;
  height: 100%;
  width: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $mid_text;

  @include medium-screen {
    width: 50px;
  }
}

.sort-table__seen--icon {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.sort-table__seen--icon--compact {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}

.sort-table__seen-text {
  margin-left: 5px;
  color: $dark_text;
}

.sort-table__cover-image {
  width: 84px;
  height: 56px;
  overflow: hidden;
  border-radius: $border-radius;
  flex: none;
  cursor: pointer;
  background-color: $item_thumb_bg;

  @include medium-screen {
    width: 120px;
    height: 80px;
  }

  img {
    width: 100%;
  }
}

.sort-table__cover-image--placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $dashboard_placeholder;
  height: 100%;
  transition: all $animation-timing;
  color: black;
  border: 1px solid transparent;
  color: $mid_text;

  &:hover {
    border: 1px solid $active-color;
    color: $active-color;
  }
}

.sort-table__labels {
  display: flex;
  flex-direction: row;
  height: 18px;

  > div {
    margin-left: 0;
    margin-right: 8px;

    @include medium-screen {
      margin-left: 8px;
      margin-right: 0;
    }
  }
}

.sort-table__desc-container {
  position: relative;
  overflow: hidden;
}

.sort-table__desc-container--compact {
  display: flex;
  flex: 1;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.sort-table__name {
  font-weight: bold;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1;
}

.sort-table__name--compact {
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: auto;
}

.sort-table__desc {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 3px;
}

.sort-table__desc--loz {
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  flex: 1;
  position: relative;

  > span {
    margin-right: 0;
    margin-left: 10px;
  }
}

.sort-table__desc--loz--compact {
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;
  flex: 1;
  position: relative;
  min-width: 160px;
  max-width: 170px;

  > span {
    margin-right: 0;
    margin-left: 10px;
  }

  .tag__container {
    margin-bottom: 0px;
  }
}

.sort-table__table--active {
  background: $dashboard_active_row;

  .sort-table__bulk-edit {
    background: $dashboard_active_row;
  }
}

.sort-table__filters {
  display: flex;
  justify-content: space-between;
  padding: 20px $padding;
  align-items: center;
  height: 48px;
  font-size: $text-small;
  background: white;
  box-shadow: 0px 9px 14px -14px rgba(0, 0, 0, 0.2);
  z-index: 2;

  span {
    width: 75px;
    display: inline-flex;
    white-space: nowrap;
    margin-left: 8px;
    align-items: center;
    cursor: pointer;

    &:first-of-type {
      margin-left: 0;
    }
  }
}

.sort-table__filters--flex-end {
  display: none;

  @include large-screen {
    display: block;
  }
  span {
    justify-content: flex-end;
  }
}

span.sort-table__filters--id {
  width: 137px;
  padding-left: 5px;

  @include medium-screen {
    width: 195px;
    padding-left: 15px;
  }
}

span.sort-table__filters--id--compact {
  width: 55px;
  padding-left: 0px;

  @include medium-screen {
    width: 55px;
    padding-left: 0px;
  }
}

.sort-table__filters--child {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sort-table__add-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid $light_border;
  height: 48px;
  background: white;
  margin: $small-padding $small-padding 0 $small-padding;
  border-radius: $border-radius;
}

.sort-table__add--left {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  padding: 10px $padding;
  cursor: pointer;
  color: $link_bright_txt;
  padding-left: $padding;
}

.sort-table__add--right {
  padding-right: 10px;
}

.sort-table__add--text {
  border: 0;
  height: 100%;
  width: 100%;
  font-size: 16px;
  margin-left: $padding;
  padding: 0;
  outline: 0;
  color: $link_bright_txt;
  font-weight: bold;

  &::placeholder {
    color: $link_bright_txt;
  }
}

.sort-table__cursor {
  position: relative;
  width: 90%;
}

.sort-table__cursor i {
  position: absolute;
  width: 1px;
  height: 60%;
  background-color: gray;
  left: 1ypx;
  top: 6px;
  animation-name: blink;
  animation-duration: 900ms;
  animation-iteration-count: infinite;
  opacity: 1;
}

.sort-table__cursor input:focus + i {
  display: none;
}

.sort-table__cursor input:focus::-webkit-input-placeholder {
  color: transparent;
}
.sort-table__cursor input:focus:-moz-placeholder {
  color: transparent;
}
.sort-table__cursor input:focus::-moz-placeholder {
  color: transparent;
}
.sort-table__cursor input:focus:-ms-input-placeholder {
  color: transparent;
}

@keyframes blink {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.sort-table__open-modal {
  cursor: pointer;
  padding: 10px;
  padding-right: $padding;

  &:hover {
    color: $active-color;
  }
}

.sort-table__pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 20px 0;
  max-width: 100vw;
  overflow: hidden;
}

.sort-table__pagination {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $white;
  opacity: 0.7;

  button {
    margin: 0 4px;
  }

  &:hover {
    opacity: 1;
  }
}

.sort-table__empty {
  display: flex;
  padding: $padding;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
}

.sort-table__empty--inner {
  width: 330px;
  text-align: center;
  padding-top: 16px;
  color: $mid_text;
  position: relative;
  top: -79px;
}

.sort-table__scroll {
  overflow-y: auto;
  flex: 1;
  background: white;
}
