@import '../../styles/vars';

.adminTranslations__changed--row {
  display: flex;
  padding-bottom: 5px;
  padding-top: 5px;
  border-bottom: 1px solid $light_border;

  > div {
    display: flex;
    flex: 1;
    > div {
      flex: 1;
    }
  }
}

.adminTranslations__changed--inner {
  text-align: center;
  flex: 1;
}

.adminTranslations__changed--left {
  max-width: 250px;
}

.adminTranslations__changed--middle {
  max-width: 50px;
}

.adminTranslation__body {
  margin-bottom: 50px;
}

.adminTranslations__changed--oldText {
  color: red;
}

.adminTranslations__changed--newText {
  color: green;
}
