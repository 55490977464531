@import '../../styles/vars';

.label__container {
  width: 55px;
  max-width: 100%;
  padding: 1px 4px;
  background: $label_bg;
  border-radius: $border-radius;
  text-align: center;
  font-size: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: all $animation-timing;

  @include medium-screen {
    font-size: 12px;
    width: 75px;
  }
}

.label__container--click {
  cursor: pointer;

  &:hover {
    background: $label_hover !important;
  }
}

.label__container--ok {
  background: #f2f5f9;
}

.label__container--warnMessage {
  background: orange;
  color: white;
}

.label__container--danger {
  background: #c62828;
  color: white;
}
