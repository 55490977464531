@import '../../styles/vars';

.admin__stats-table {
  border: 1px solid black;
  border-radius: $border-radius;
}

.admin__stats-table--row {
  border-bottom: 1px solid black;
  display: flex;
  justify-content: space-between;
  padding: 10px;

  &:last-of-type {
    border-bottom: 0;
  }
}

.admin__stats-lists-container {
  display: grid;
  grid-template-columns: 48% 48%;
  grid-column-gap: 2%;
  margin-top: $size-huge;

  > div {
    margin-bottom: $size-huge;
  }
}

.admin__stats-container {
  display: flex;
  flex-wrap: wrap;
}
