@import '../../styles/vars';

.sort-table__bulk {
  padding-left: 20px;
  font-weight: bold;

  @include medium-screen {
    padding-left: 30px;
  }
}

.sort-table__bulk--compact {
  padding-left: 0px;
  font-weight: bold;

  @include medium-screen {
    padding-left: 0px;
  }
}

.sort__title--active {
  color: $link_bright_txt;
}
