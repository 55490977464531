@import '../../styles/vars';

.drop-down__container {
  position: relative;
  cursor: pointer;
}

.drop-down__menu {
  position: absolute;
  top: 26px;
  left: 2px;
  background: white;
  z-index: 2;
  border-radius: $border-radius;
  min-width: 250px;
  transition: all $animation-timing;
}

.drop-down__menu--right {
  left: auto;
  right: 0;
}

.drop-down__menu--border {
  > div {
    border: 1px solid $light_border;
    border-radius: $border-radius;
    overflow: hidden;
  }
}

.drop-down__container--inline {
  display: inline-block;
}

.drop-down__menu--hidden {
  opacity: 0;
  pointer-events: none;
  display: none;
}
