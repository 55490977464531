@import '../../styles/vars';

.register__split {
  display: flex;
  flex-direction: column;

  @include medium-screen {
    flex-direction: row;
  }

  > div {
    flex: 1;
  }

  > div:first-child {
    margin-right: 20px;
  }
}
