@import '../../styles/vars';

.tag__container {
  padding: 2px 8px;
  background: $tag_bg;
  border-radius: 15px;
  color: $light-text;
  font-size: 10px;
  text-transform: uppercase;
  margin-right: 5px;
  display: inline-block;
  white-space: nowrap;
  margin-bottom: 5px;
  transition: all $animation-timing;

  @include medium-screen {
    padding: 3px 10px;
    font-size: 12px;
  }
}

.tag__container--no-bottom {
  margin-bottom: 0;
}

.tag__container--active {
  background: $tag_active_bg;
}

.tag__container--right-pad {
  margin-left: 5px;
  margin-right: 0;
}

.tag__container--click {
  cursor: pointer;
}

.tag__container--remove {
  &:hover {
    background: $danger;
  }
}

.tag__container--disabled {
  cursor: not-allowed !important;
  background: $button_disabled;
  pointer-events: none;
}
