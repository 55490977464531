@import '../../styles/vars';

.unassigned__statuses {
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;

  > div {
    min-width: 20%;
    margin-bottom: 10px;
    text-align: center;
    padding: 0 10px;
  }
}

.report__small-cell {
  width: 150px;
  text-align: center;
}

.report__details {
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
  justify-content: center;

  > div {
    min-width: 40%;
    margin-bottom: 10px;
    text-align: center;
    padding: 0 10px;
  }
}
