.admin-team__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;

  h1 {
    margin-bottom: 0;
  }
}
