@import '../../styles/vars';

.thumb-nail__container {
  border: 1px solid $dark_border;
  border-radius: $border-radius;
  height: 60px;
  width: 60px;
  transition: all $animation-timing;
  margin-right: $padding;
  display: block;
  position: relative;
}

.thumb-nail__container--flex {
  width: 100%;
  height: 100%;
}

.thumb-nail__container--no-padding {
  margin-right: 0;
}

.thumb-nail__container--click {
  cursor: pointer;
}

.thumb-nail__item {
  background-size: auto 100%;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  border-radius: $border-radius;
}

.thumb-nail__container--dark-border {
  border: 1px solid $light_border;
}

.thumb-nail__container--large {
  height: 100px;
  width: 100px;

  &:focus {
    outline: none;
  }
}

.thumb-nail__container--delete-icon {
  &:after {
    content: 'x';
    position: absolute;
    top: -5px;
    right: -5px;
    width: 15px;
    height: 15px;
    background: red;
    border-radius: 50%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    padding-bottom: 2px;
    opacity: 0.6;
  }
}

.thumb-nail__container--active {
  border: 3px solid $link_bright_txt;
}
