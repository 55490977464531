.profile-pic__container {
  padding-bottom: 20px;
}

.profile__picture {
  margin: 0 auto;
  text-align: center;
}

.cropper-crop-box,
.cropper-view-box {
  border-radius: 50%;
}

.cropper-view-box {
  box-shadow: 0 0 0 1px #39f;
  outline: 0;
}

.cropper-face {
  background-color: inherit !important;
}

.cropper-dashed,
.cropper-point.point-se,
.cropper-point.point-sw,
.cropper-point.point-nw,
.cropper-point.point-ne,
.cropper-line {
  display: none !important;
}

.cropper-view-box {
  outline: inherit !important;
}

.profile-pic__container--dragger {
  margin-bottom: 20px;
}

.profile-pic__container--text {
  text-align: center;
  margin-top: 40px;

  h3 {
    margin-bottom: 20px;
  }
}
