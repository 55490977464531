@import '../../styles/vars';

.dashboard__top-text {
  font-weight: bold;
  margin-right: 5px;
  display: inline-block;
  min-width: 75px;
  display: flex;
  align-items: center;
}

.dashboard__top-filter-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.dashboard__top-filter-child {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
}
