@import '../../styles/vars';

.seen__container {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background: $dashboard_seen;
}

.seen__container--active {
  background: $active-color;
}
