@import '../../styles/vars';

.issue-document__container {
  display: flex;
  padding-top: $other-padding;
  padding-right: $small-padding;

  h5 {
    margin-bottom: 5px;
  }

  > div {
    margin-right: 8px;
  }
}

.issue-document__button {
  display: flex;
  margin: 0 10px 10px;
  justify-content: center;
}
