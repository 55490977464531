@import '../../styles/vars';

.top-nav__container {
  display: flex;
  color: $top_bar_txt;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 100;
  height: $top-nav-height;
  flex-direction: column;
  background-color: $top_header_bg;
}

.top-nav__logo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: $padding;

  img {
    height: 35px;
  }
}

.top-nav__navigation {
  display: flex;
  z-index: 1;
  margin-right: $padding;
  align-items: center;

  > * {
    text-transform: capitalize;
    margin-left: $padding * 2;
  }
}

.top-nav__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $top_header_bg;
  padding: $size-small 0;
  width: 100%;
  height: $top-nav-height;
  position: relative;
}

.top-nav__notifcations {
  position: absolute;
  top: -12px;
  left: 0;
  width: 30px;
  height: 24px;
}

.top-nav__notifcations--badge {
  z-index: 2;
}

.top-nav__notifcations--icon {
  position: absolute;
  top: -2px;
  left: 13px;
  z-index: -1;

  > span,
  svg {
    height: 27px;
    width: 27px;
  }
}

.top-nav__admin-links {
  a {
    padding: 5px 10px;
  }
}

.top-nav__avatar {
  margin-right: 8px;
  height: 28px;
}

.top-nav__middle-pad {
  margin-left: 15px;
}

.top-nav__back {
  margin-right: 15px;
  cursor: pointer;

  @include medium-screen {
    display: none;
  }
}

.menu__button--topNav {
  padding: 0;
  padding-top: 2px;
  padding-bottom: 2px;
  background-color: #21242a;
  display: inline-flex;
  justify-content: center;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  border: none;
}

.menu__items--topNav {
  transform-origin: top right;
  position: absolute;
  right: 0;
  width: 9rem;
  border-radius: 0.375rem;
  background-color: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.12);
  z-index: 2;
}
